body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", "Roboto",
    sans-serif;
  /* font-family: 'Roboto', sans-serif; */
  letter-spacing: 1.1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 1.5px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "SF-PRO"; /* Give your font a name to use in CSS */
  src: url("./components/fonts/SF-Pro.ttf") format("opentype"); /* Correct path to your font file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}
